import { mq } from '@gameonsports/components/cjs/_utils/styled-components-utils'
import styled from 'styled-components'

const BoxContainer = styled.section<{ noPadding?: boolean }>`
  background-color: ${props => props.theme.white400};
  border: solid 1px ${props => props.theme.grey400};
  border-radius: 3px;
  padding: ${({ noPadding }) => !noPadding && '1rem'};
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);

  ${mq.up('tablet')} {
    padding: ${({ noPadding }) => !noPadding && '2.375rem 2.625rem'};
  }
`

export default BoxContainer
