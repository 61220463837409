import { useToggle } from '@gameonsports/components/cjs/_hooks/useToggle'
import Button from '@gameonsports/components/cjs/Button'
import Icon from '@gameonsports/components/cjs/Icon'
import { Text } from '@gameonsports/components/cjs/TextV3'
import chunk from 'lodash/fp/chunk'
import compose from 'lodash/fp/compose'
import take from 'lodash/fp/take'
import React from 'react'
import styled from 'styled-components'
import { nonFalsy } from '../../utils/array'
import { media } from '../../utils/styled-components-utils'
import FullWidthBlock from '../FullWidthBlock'
import OrgLogoName from '../OrgLogoName'

const Container = styled.div`
  width: 100%;
  margin-top: auto;
  padding: 3rem 0;
  background-color: ${props => props.theme.white400};

  ${media.tablet`
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  `}
`

const ItemLink = styled(Text).attrs({ as: 'a' })<
  React.AnchorHTMLAttributes<{}>
>`
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  align-items: center;
  background-image: linear-gradient(
    ${props => props.theme.white400},
    ${props => props.theme.white400}
  );

  &:hover {
    color: ${props => props.theme.black400};
  }

  svg {
    fill: currentColor;
  }
`

const ItemIcon = styled(Icon)`
  margin-right: 1rem;
  flex-shrink: 0;
`

const StyledOrgLogoName = styled(OrgLogoName)`
  padding: 0;
`

const OrgDetails = styled.section`
  padding-top: 1rem;
  display: grid;
  grid-gap: 2rem;
  color: ${props => props.theme.darkGrey400};

  ${media.tablet`
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    padding-left: 3rem;
  `};
`

const Address = styled.address`
  line-height: 1.5rem;
`

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;

  a + a {
    margin-top: 1rem;
  }

  ${media.tablet`
    a + a {
      margin-top: 0.5rem;
    }
  `}
`

const OrgContacts = styled.div`
  margin-top: 1rem;
`

const Contact = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tablet`
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 1fr 1fr;    
 `}
`

const ContactRow = styled(OrgDetails)`
  &:not(:last-child) {
    padding-bottom: 1.5rem;
  }

  ${media.tablet`
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid ${props => props.theme.bodyBackground};
    
    &:not(:last-child) {
      padding-bottom: 0;
    }
  `}
`

const ContactGroup = styled.div`
  display: flex;
  flex-direction: column;

  & > ${Text}:not(:first-child),
  & > ${ItemLink} {
    padding-top: 0.5rem;
  }

  &:nth-child(2n) {
    padding-top: 0.5rem;
  }

  ${media.tablet`
    display: grid;
    grid-gap: 0.5rem;
    grid-template-rows: 1fr 1fr;
    
    & > ${Text}:not(:first-child),
    & > ${ItemLink} {
      padding-top: 0;
    }
    
    &:nth-child(2n) {
      padding-top: 0;
    }
  `}
`

const ContactActions = styled.div`
  width: 100%;
  padding-top: 3rem;
  text-align: center;
`

const ToggleButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: transparent;

  svg {
    padding: 0.15rem;
    background-color: ${props => props.theme.blueberry400};
    color: ${props => props.theme.white400};
    border-radius: 50%;
  }
`

interface OrganisationContact {
  id: string
  firstName: string
  lastName: string
  position: string
  email?: string | null
  phone?: string | null
}

interface OrganisationAddress {
  line1: string
  suburb: string
  postcode: string
  state: string
  country: string
}

interface OrganisationFooterProps {
  name?: string
  email?: string
  contactNumber?: string
  websiteUrl?: string | null
  address?: OrganisationAddress | null
  logo?: {
    sizes: Array<{
      url: string
      dimensions: {
        width: number
        height: number
      }
    }>
  } | null
  contacts: OrganisationContact[]
}

const MAX_VISIBLE_CONTACTS = 3
const chunks = chunk(MAX_VISIBLE_CONTACTS)
const firstChunk = compose(take(1), chunk(MAX_VISIBLE_CONTACTS))
const getAddressText = (address?: OrganisationAddress | null) => {
  if (!address) return ''

  return [
    address.line1,
    address.suburb,
    [address.state, address.postcode].filter(nonFalsy).join(' '),
    address.country,
  ]
    .filter(nonFalsy)
    .join(', ')
}

/*
  Don't show address if it's not defined or doesn't have essential information
 */
const shouldShowAddress = (address?: OrganisationAddress | null) => {
  if (!address) return false

  return [address.line1, address.suburb, address.state, address.postcode].some(
    nonFalsy,
  )
}

const OrganisationFooter: React.FC<OrganisationFooterProps> = ({
  name,
  contactNumber,
  email,
  websiteUrl,
  address,
  logo,
  contacts,
}) => {
  const [isContactsVisible, toggleContactsVisible] = useToggle()
  const fullAddress =
    address &&
    `${address.line1}, ${address.suburb} ${address.state} ${address.postcode}`

  return (
    <Container>
      <FullWidthBlock>
        {name && (
          <StyledOrgLogoName
            name={name}
            logo={logo}
            weight="700"
            size="normal"
          />
        )}
        <OrgDetails>
          {shouldShowAddress(address) && (
            <Address>
              <ItemLink
                href={`https://maps.google.com/?q=${fullAddress}`}
                target="_blank"
                rel="nofollow noopener"
                className="link-hover"
                data-testid="org-footer-address-link"
              >
                {getAddressText(address)}
              </ItemLink>
            </Address>
          )}
          <ContactDetails>
            {contactNumber && (
              <ItemLink
                href={`tel:${contactNumber}`}
                className="link-hover"
                size="16"
                data-testid="org-footer-number-link"
              >
                <ItemIcon name="phone" size="16" />
                {contactNumber}
              </ItemLink>
            )}
            {email && (
              <ItemLink
                href={`mailto:${email}`}
                size="16"
                className="link-hover"
                data-testid="org-footer-email-link"
              >
                <ItemIcon name="mail" size="16" />
                {email}
              </ItemLink>
            )}
          </ContactDetails>
          {websiteUrl && (
            <div>
              <Button
                variant="quaternary"
                as="a"
                href={websiteUrl}
                target="_blank"
                icon="share"
                iconPosition="right"
                size="small"
                data-testid="org-footer-website-link"
              >
                Visit website
              </Button>
            </div>
          )}
        </OrgDetails>
        {contacts && contacts.length > 0 && (
          <>
            <OrgContacts>
              {(isContactsVisible ? chunks : firstChunk)(contacts).map(
                (contactsChunk: OrganisationContact[], index: number) => (
                  <ContactRow key={index} data-testid={`contact-row-${index}`}>
                    {contactsChunk.map(contact => (
                      <Contact
                        key={contact.id}
                        data-testid={`contact-${contact.id}`}
                      >
                        <ContactGroup>
                          <Text>
                            {contact.firstName} {contact.lastName}
                          </Text>
                          <Text weight="600">{contact.position}</Text>
                        </ContactGroup>

                        <ContactGroup>
                          {contact.phone && (
                            <ItemLink
                              href={`tel:${contact.phone}`}
                              className="link-hover"
                              size="16"
                            >
                              <ItemIcon name="phone" size="16" />
                              {contact.phone}
                            </ItemLink>
                          )}
                          {contact.email && (
                            <ItemLink
                              href={`mailto:${contact.email}`}
                              size="16"
                              className="link-hover"
                            >
                              <ItemIcon name="mail" size="16" />
                              {contact.email}
                            </ItemLink>
                          )}
                        </ContactGroup>
                      </Contact>
                    ))}
                  </ContactRow>
                ),
              )}
            </OrgContacts>
            {contacts.length > MAX_VISIBLE_CONTACTS && (
              <ContactActions>
                <ToggleButton
                  type="button"
                  variant="text"
                  onClick={toggleContactsVisible}
                  iconSize="16"
                  icon={isContactsVisible ? 'minus' : 'plus'}
                  color="blueberry400"
                >
                  Show {isContactsVisible ? 'less' : 'more'} contacts
                </ToggleButton>
              </ContactActions>
            )}
          </>
        )}
      </FullWidthBlock>
    </Container>
  )
}

export default OrganisationFooter
