import styled from 'styled-components'
import { media } from '../../utils/styled-components-utils'

interface SectionContainerProps {
  noXPadding?: boolean
  noYPadding?: boolean
}

const SectionContainer = styled.section<SectionContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${props =>
    `${props.noYPadding ? '0' : '1rem'} ${props.noXPadding ? '0' : '1rem'}`};

  ${media.desktop`
    padding: ${(props: SectionContainerProps) =>
      `${props.noYPadding ? '0' : '2.5rem'} ${
        props.noXPadding ? '0' : '2.5rem'
      }`};
  `}

  & > *:not(:last-child) {
    margin-bottom: 1rem;

    ${media.tablet`
      margin-bottom: 1.5rem;
    `}
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 1rem;

    ${media.tablet`
      grid-gap: 1.5rem;
    `}

    & > *:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

export default SectionContainer
