export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined
}

export function nonFalsy<T>(value: T | boolean): value is T {
  return !!value
}

export const sortByOrder = (
  a: { order?: number | null },
  b: { order?: number | null },
) => {
  if (typeof a.order === 'number' && typeof b.order === 'number')
    return a.order - b.order
  if (typeof a.order === 'number') return -1
  if (typeof b.order === 'number') return 1
  return 0
}
