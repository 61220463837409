import React from 'react'
import styled from 'styled-components'
import { media } from '../../utils/styled-components-utils'

type Size = 'normal' | 'small'

interface HeaderProps {
  size?: Size
}

const Header = styled.header<HeaderProps>`
  padding: 2rem 1rem 1.75rem;
  display: flex;
  align-items: flex-end;
  color: ${props => props.theme.white400};

  ${media.tablet`
    padding-top: ${(props: HeaderProps) =>
      props.size === 'normal' ? '5rem' : '3.75rem'};
      padding-bottom: 2.125rem;
  `}
`

const HeaderContent = styled.div`
  display: grid;
  justify-items: start;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  color: ${props => props.theme.white400};

  ${media.tablet`
    max-width: 60rem;
  `}
`

const PageHeader: React.FC<HeaderProps> = ({ size = 'normal', children }) => (
  <Header size={size}>
    <HeaderContent>{children}</HeaderContent>
  </Header>
)

export default PageHeader
