import compose from 'lodash/fp/compose'
import replace from 'lodash/fp/replace'
import startCase from 'lodash/fp/startCase'
import {
  GetRegistrationContacts,
  TenantContactRolesConfigurationContactRoles,
} from '../generated/graphql'

const sentenceCase = compose(startCase, replace('_', ' '))

export const setOrganisationContacts = <
  T extends { contacts: GetRegistrationContacts[] },
>(
  organisation: T,
  contactRoles: TenantContactRolesConfigurationContactRoles[],
) => {
  if (!organisation) return organisation

  return {
    ...organisation,
    contacts: organisation.contacts.map(contact => {
      const role = contactRoles.find(({ value }) => contact.position === value)

      return {
        ...contact,
        position: role ? role.name : sentenceCase(contact.position),
      }
    }),
  }
}
