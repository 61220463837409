import Button from '@gameonsports/components/cjs/Button'
import { Text } from '@gameonsports/components/cjs/TextV3'
import React from 'react'
import styled from 'styled-components'
import { media } from '../../utils/styled-components-utils'
import BoxContainer from '../BoxContainer'

const Container = styled(BoxContainer)`
  background-size: 14px 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    to bottom,
    ${props => props.theme.blueberry400},
    ${props => props.theme.grape400}
  );
  text-align: center;
  line-height: 1.6;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;

  & {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }

  p {
    margin: 0;
  }

  .feedbackButton {
    margin-top: 2.5rem;
  }

  ${media.tablet`
    & {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
      padding-bottom: 2.375rem
    }
  `}
`

const Heading = styled(Text)`
  margin-bottom: 1.5rem;
  margin-top: 0;
`

const FeedbackAndSupport: React.FC<{ className?: string }> = ({
  className,
}) => (
  <Container className={className}>
    <Heading size="28" weight="700" color="blackberry400" as="h3">
      Feedback And Support
    </Heading>

    <Text as="p">
      If you need assistance using PlayHQ, please check our support centre.
      Feedback on any aspect of PlayHQ is welcomed and can be provided using the
      feedback form in the support centre.
    </Text>

    <Button
      variant="primary"
      icon="right-arrow-strong"
      iconPosition="right"
      className="feedbackButton"
      as="a"
      href="https://support.playhq.com/"
    >
      Find out more
    </Button>
  </Container>
)

export default FeedbackAndSupport
