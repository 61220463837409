import {
  NavigateOptions,
  WindowLocation,
  useLocation,
  useNavigate,
} from '@reach/router'

export type LocationState = {
  /**
   * Render an error notification on the page after navigation.
   */
  notification?: string
  /**
   * Global flag to redirect the default tab (competitions) to registrations if
   * the organisation has no competitions/programs.
   */
  redirectToRegistrationTab?: boolean
}

/**
 * @returns @reach/router `useNavigate` hook with typed State
 */
export const useNavigateWithState = () =>
  useNavigate() as (
    to: string,
    options?: NavigateOptions<LocationState> & { replace?: boolean },
  ) => void

/**
 * @returns @reach/router `useLocation` hook with typed State
 */
export const useLocationWithState = <T>() =>
  useLocation() as WindowLocation<(Partial<T> & LocationState) | null>
